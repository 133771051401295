<template>
  <page-title :title="$t('notifications.title')" />
  <div
    class="default-card-border default-rounded bg-white overflow-hidden q-mt-xl"
  >
    <messages
      :show-skeleton="inRequest"
      :show-icon="true"
      item-class="q-pa-md"
      :messages="notifications"
    />
  </div>
</template>

<script>
import Messages from "@/modules/main/pages/notifications/components/messages";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";

const { useActions, useGetters } = createNamespacedHelpers("notifications");

export default {
  components: { Messages, PageTitle },

  setup() {
    const { fetchNotificationsAction } = useActions([
      "fetchNotificationsAction",
    ]);
    fetchNotificationsAction();

    const { inRequest, notifications } = useGetters({
      inRequest: "getNotificationsOnRequest",
      notifications: "getAllNotifications",
    });

    return {
      inRequest,
      notifications,
    };
  },
};
</script>
